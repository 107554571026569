<template>
  <div class="flex-row-fluid col-lg-9" :class="$vuetify.breakpoint.smAndDown ? 'mt-10' : ''">
    <!--begin::Card-->
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <!--begin::Card-->
      <div class="card card-custom card-stretch">
        <!--begin::Header-->
        <div class="card-header py-3">
          <div class="card-title align-items-start flex-column">
            <h3 class="card-label font-weight-bolder text-dark">Dados Pessoais</h3>
            <span class="text-muted font-weight-bold font-size-sm mt-1">Atualização dos meus dados pessoais</span>
          </div>
        </div>
        <!--end::Header-->

        <!--begin::Body-->
        <div class="card-body">
          <!--begin::Form-->
          <!-- Error message-->
          <b-alert :show="disableEdit" variant="info">
            Para realizar a alteração dos seus dados pessoais, contacte o suporte.
          </b-alert>

          <div
            v-if="Object.keys(errors).length"
            role="alert"
            :class="{ show: Object.keys(errors).length }"
            class="alert fade alert-danger"
          >
            <div v-for="(error, i) in errors" :key="i" class="alert-text">
              {{ error }}
            </div>
          </div>

          <!-- Input FullName -->
          <b-form-group label="Nome Completo">
            <b-form-input
              v-if="!disableEdit"
              id="user_name"
              v-model="$v.form.name.$model"
              autocomplete="off"
              class="form-control form-control-solid h-auto py-5 px-6 rounded disabled"
              :class="!$v.form.name.required ? 'is-invalid' : 'is-valid'"
              name="example-input-0"
              :state="validateState('name')"
            />
            <h3 v-else>
              <b-badge variant="light">{{ $v.form.name.$model }}</b-badge>
            </h3>

            <b-form-invalid-feedback>Nome completo obrigatório.</b-form-invalid-feedback>
          </b-form-group>
          <!-- Documento -->
          <div class="row">
            <!-- Tipo documento -->
            <div class="col-xl-6">
              <b-form-group label="Tipo">
                <b-form-radio-group
                  v-if="!disableEdit"
                  id="type"
                  v-model="$v.form.type.selected.$model"
                  :options="form.type.options"
                  buttons
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                ></b-form-radio-group>
                <h3 v-else>
                  <b-badge variant="light">
                    {{ form.type.options.find((option) => option.value == $v.form.type.selected.$model).text }}
                  </b-badge>
                </h3>
              </b-form-group>
            </div>

            <!-- Numero documento -->
            <div class="col-xl-6">
              <b-form-group label="Número Documento">
                <the-mask
                  v-if="!disableEdit"
                  v-model="$v.form.code.$model"
                  :mask="form.type.options.find((option) => option.value == $v.form.type.selected.$model).mask"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  :class="!$v.form.code.required ? 'is-invalid' : 'is-valid'"
                  :state="validateState('code')"
                />
                <h3 v-else>
                  <b-badge variant="light">
                    {{ $v.form.code.$model }}
                  </b-badge>
                </h3>
                <div v-if="!$v.form.code.required" class="invalid-feedback">Dcoumento é obrigatório.</div>
              </b-form-group>
            </div>
          </div>

          <!-- Data nascimento e sexo-->
          <div class="row">
            <div class="col-xl-6">
              <b-form-group label="Data Nascimento">
                <the-mask
                  v-if="!disableEdit"
                  v-model="$v.form.date_birth.$model"
                  :mask="['##/##/####']"
                  :masked="true"
                  class="form-control form-control-solid h-auto py-5 px-6 rounded"
                  :class="!$v.form.date_birth.required ? 'is-invalid' : 'is-valid'"
                  :state="validateState('date_birth')"
                  :disabled="disableEdit"
                />
                <h3 v-else>
                  <b-badge variant="light">{{ $v.form.date_birth.$model }}</b-badge>
                </h3>
                <b-form-invalid-feedback>Data de nasicmento obrigatória!</b-form-invalid-feedback>
              </b-form-group>
            </div>
            <!-- Sexo-->
            <div class="col-xl-6">
              <b-form-group label="Sexo">
                <b-form-radio-group
                  v-if="!disableEdit"
                  v-model="$v.form.genre.selected.$model"
                  :options="form.genre.options"
                  buttons
                  button-variant="outline-primary"
                  size="lg"
                  name="radio-btn-outline"
                ></b-form-radio-group>
                <h3 v-else>
                  <b-badge variant="light">
                    {{ form.genre.options.find((option) => option.value === $v.form.genre.selected.$model).text }}
                  </b-badge>
                </h3>
              </b-form-group>
            </div>
          </div>

          <!-- Email -->
          <b-form-group label="Email">
            <b-form-input
              v-model="$v.form.email.$model"
              class="form-control form-control-solid h-auto py-5 px-6 rounded"
              name="example-input-0"
              :class="!$v.form.name.required ? 'is-invalid' : 'is-valid'"
              :state="validateState('email')"
            />

            <b-form-invalid-feedback v-if="!$v.form.email.required">Email obrigatório.</b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.form.email.email">Email invalido.</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <!--end::Body-->

        <!--begin::Footer Actions-->
        <div class="card-footer d-flex justify-content-between">
          <button
            ref="submit"
            type="submit"
            class="btn btn-success font-weight-bold font-size-3 btn-lg"
            :disabled="isSubmitting"
            :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
          >
            Salvar Dados
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import { mapState, mapGetters } from 'vuex';
import { UPDATE_PERSONAL_DATA } from '@/core/services/store/modules/profile';

export default {
  components: { TheMask },
  mixins: [validationMixin],
  data() {
    return {
      disableEdit: this.$store.getters.currentStatus.id == 2 ? false : true,
      isSubmitting: false,
      form: {
        name: this.$store.getters.currentCustomer.name,
        date_birth: this.formatDateGet(this.$store.getters.currentCustomer.date_birth),
        email: this.$store.getters.currentUser.email,
        genre: {
          selected: this.$store.getters.currentCustomer.genre,
          options: [
            {
              text: 'Masculino',
              value: 'M',
              disabled: this.$store.getters.currentCustomer.type == '1' ? false : true
            },
            {
              text: 'Feminino',
              value: 'F',
              disabled: this.$store.getters.currentCustomer.type == '1' ? false : true
            },
            {
              text: 'Indefinido',
              value: 'I',
              disabled: this.$store.getters.currentCustomer.type == '2' ? false : true
            }
          ]
        },
        type: {
          selected: this.$store.getters.currentCustomer.type,
          options: [
            { text: 'Pessoa Fisíca', value: '1', mask: '###.###.###-##' },
            { text: 'Pessoa Jurídica', value: '2', mask: '##.###.###/####-##' }
          ]
        },
        code: this.$store.getters.currentCustomer.code
      }
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.profile.errors
    }),
    ...mapGetters(['currentStatus', 'currentCustomer'])
  },
  validations: {
    form: {
      name: {
        required
      },
      date_birth: {
        required
      },
      genre: {
        selected: {
          required
        }
      },
      email: {
        required,
        email
      },
      type: {
        selected: {
          required
        }
      },
      code: {
        required
      }
    }
  },
  watch: {
    'form.type.selected': function (value) {
      this.form.genre.options[0].disabled = value == 2;
      this.form.genre.options[1].disabled = value == 2;
      this.form.genre.options[2].disabled = value != 2;

      this.form.genre.selected = value == 2 ? 'N' : 'M';
      this.form.code = '';
    },
    deep: true
  },
  mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [{ title: 'Perfil' }, { title: 'Dados Pessoais' }]);
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      const form = {
        name: this.$v.form.name.$model,
        date_birth: this.$v.form.date_birth.$model,
        genre: this.$v.form.genre.selected.$model,
        email: this.$v.form.email.$model,
        type: this.$v.form.type.selected.$model,
        code: this.$v.form.code.$model
      };

      // send register request
      await this.$store
        .dispatch(UPDATE_PERSONAL_DATA, form)
        .then((response) => {
          this.$swal({
            title: response.message,
            icon: 'success'
          });
          this.isSubmitting = false;
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    formatDateGet(value) {
      return this.$moment(value).format('DD/MM/YYYY');
    }
  }
};
</script>
